import React, { Fragment, useContext, useEffect, useState } from 'react';
import useMissionClock from '../../hooks/useMissionClock';
import ClientContext from '../../helpers/ClientContext';
import Status from '../widgets/status';
import useListItems from '../../hooks/useListItems';
import DisplayList from '../widgets/displayList';
import { useWindowSize } from '../../helpers/helper';
import { roles } from '../../enums';
import '../../App.css';

const Checklist = () => {
    const { widgetState, run, authedUser, client } = useContext(ClientContext);
    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);
    const assets = useListItems('assets');
    const screenSize = useWindowSize();
    const curChecklistId = useListItems('checklistId', run.checklistId || 0);
    const checklists = useListItems('checklists', run.checklists);
    const [checklist, setChecklist] = useState(JSON.parse(JSON.stringify(run.checklists[run.checklistId] || [])));

    useEffect(() => {
        const newChecklist = JSON.parse(JSON.stringify(checklists[curChecklistId] || []));
        let foundUnchecked = false;
        newChecklist.forEach((row, index) => {
            if (foundUnchecked) {
                row.hide = false;
                return;
            }

            if (authedUser.role === roles.ADMIN || authedUser.position === run.checklistPosition || String(run.checklistPosition) === '-1') {
                row.hide = false;
                return;
            }

            if (!row.checked) {
                foundUnchecked = true;
                row.hide = false;
                return;
            }
            if (!newChecklist[index + 1]?.checked) {
                row.hide = false;
                return;
            }
            if (!newChecklist[index + 2]?.checked) {
                row.hide = false;
                return;
            }
            row.hide = true;
        });
        setChecklist(newChecklist);
    }, [checklists, curChecklistId, authedUser.role, authedUser.position, run.checklistPosition]);

    let clPostLaunchFactor
    let isclPostLaunch
    let missionClockPostLaunch
    useEffect(() => {
        const newChecklist = checklist.map((row, index) => {
            if (!row.time || row.checked) {
                return row;
            }

            let time = row.time.replace('L', '').split(' – ').join('');
            if (time != time.replace('T', '')) {
                clPostLaunchFactor = -1
                isclPostLaunch = false
                time = time.replace('T', '')
            }
            else {
                clPostLaunchFactor = 1
                isclPostLaunch = true
            }
            if (missionClock.isLaunched) {
                missionClockPostLaunch = 1
            } else {
                missionClockPostLaunch = -1
            }
            let [clHour, clMinute, clSecond] = splitString(time);
            let [utcHour, utcMinute, utcSecond] = splitString(missionClock.tMinusString);
            let clTotalSeconds = ((Number(clHour) * 3600) + (Number(clMinute) * 60) + Number(clSecond)) * missionClockPostLaunch;
            let utcTotalSeconds = ((Number(utcHour) * 3600) + (Number(utcMinute) * 60) + Number(utcSecond)) * clPostLaunchFactor;

            if (!isclPostLaunch && missionClockPostLaunch == -1) {
            if (!row.checked && (utcTotalSeconds >= clTotalSeconds && (utcTotalSeconds - clTotalSeconds) < 30)) {
                row.backgroundColor = 'yellow';
            } else if (!row.checked && (utcTotalSeconds > clTotalSeconds && (utcTotalSeconds - clTotalSeconds) >= 30)) {
                row.backgroundColor = 'red';
            } else if (clTotalSeconds > utcTotalSeconds ) {
                row.backgroundColor = 'white';
            }
        }   else if (isclPostLaunch && missionClockPostLaunch == 1) {
            if (!row.checked && (utcTotalSeconds >= clTotalSeconds && (utcTotalSeconds - clTotalSeconds) < 30)) {
                row.backgroundColor = 'yellow';
            } else if (!row.checked && (clTotalSeconds < utcTotalSeconds && (utcTotalSeconds - clTotalSeconds) >= 30)) {
                row.backgroundColor = 'red';
            } else if (clTotalSeconds > utcTotalSeconds ) {
                row.backgroundColor = 'white';
            }
            
        }   else if ((!isclPostLaunch) && (missionClockPostLaunch == 1)) {
            if (!row.checked && clTotalSeconds - utcTotalSeconds < 30){
                row.backgroundColor = 'yellow'
            }
            if (!row.checked && clTotalSeconds - utcTotalSeconds >= 30) {
                row.backgroundColor = 'red'
            }

        }
            return row;
        });

        setChecklist(newChecklist);

    }, [missionClock.tMinusString]);

    function splitString(timeString) {
        const arr = timeString.split(/[:.]/);
        return [parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]), parseInt(arr[3])];
    }

    function uncheckBoxes() {
        if (!(authedUser.role === roles.ADMIN || authedUser.position === run.checklistPosition || String(run.checklistPosition) === '-1')) {
            return;
        }

        const newChecklist = checklist.map((row, index) => {
            row.backgroundColor = 'white';
            if (row.checked) {
                row.checked = false;
                checkCheckbox(index, row.checked);
            }
            row.hide = false;
            return row;
        });

        setChecklist(newChecklist);
    }

    
    function checkCheckbox(index, checked) {
        console.log("Checked")
    if (!(authedUser.role === roles.ADMIN || authedUser.position === run.checklistPosition || String(run.checklistPosition) === '-1')) {
        console.log("Checked")
        return;
    }
        

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'checkCheckbox',
                row: index,
                checklistId: curChecklistId,
                checked
            })
        );

        const newChecklist = [...checklist];
        newChecklist[index].checked = checked;
        newChecklist[index].backgroundColor = 'white';
        setChecklist(newChecklist);
    }

    return (
        <>
            <div className="blackBody" />
            <div style={{ display: 'flex' }}>
                <div style={{
                    flexDirection: 'row',
                    marginTop: '5em',
                    marginLeft: '2%',
                    color: 'white',
                    transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
                    transformOrigin: 'top left',
                }}>
                    <div style={{ flexDirection: 'column' }}>
                        <table className="timeTable">
                            <tbody>
                            <tr>
                                <td>Zulu</td>
                                <td>{missionClock.utcString}</td>
                            </tr>
                            <tr style={{ color: missionClock.isLaunched ? 'green' : 'red' }}>
                                <td>T-Minus</td>
                                <td style={{ textAlign: 'right' }}>{missionClock.tMinusString}</td>
                            </tr>
                            <tr>
                                <td>LiftOff</td>
                                <td style={{
                                    backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                    textAlign: 'right'
                                }}>
                                    {missionClock.liftoffString}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <p style={{
                            color: 'white',
                            textAlign: 'left',
                            fontFamily: 'Courier New',
                            fontSize: '25px',
                            marginTop: '3em'
                        }}>
                            <b>Status Board:</b>
                        </p>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4,max-content)',
                            gap: '0 2rem',
                            fontSize: '1.3rem'
                        }}>
                            {assets.map((item, index) => (
                                <Fragment key={index}>
                                    <div style={{
                                        color: 'white',
                                        fontFamily: 'Courier New'
                                    }}>
                                        <b>{item.text}</b>
                                    </div>
                                    <div style={{ paddingRight: '30px' }}>
                                        <Status radius={13} bounds={{ Infinity: item.color }} />
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>

                    <div style={{
                        position: 'absolute',
                        marginTop: '5em',
                        paddingRight: '1rem'
                    }}>
                        <p style={{
                            color: 'white',
                            textAlign: 'left',
                            fontFamily: 'Courier New',
                            fontSize: '25px'
                        }}>
                            <b>Status:</b>
                        </p>
                        <div style={{ fontSize: '1.3rem' }}>
                            <DisplayList
                                listName="conditions"
                                textColor="white"
                                paddingLeft="0"
                                paddingBottom="0.7rem"
                            />
                        </div>
                    </div>

                </div>

                <div style={{
                    position: 'absolute',
                    padding: '2em',
                    marginTop: '2em',
                    color: 'black',
                    backgroundColor: 'white',
                    marginRight: 'auto',
                    marginLeft: '25%',
                    overflowY: 'scroll',
                    height: '80%',
                    width: '66%',
                    borderRadius: '.5em'
                }}>
                    <div>
                        <table className="table container">
                            <thead>
                            <tr>
                                <td>
                                    <button onClick={uncheckBoxes}>Reset Checkboxes</button>
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">NO</th>
                                <th scope="col">Check Box</th>
                                <th scope="col">Time (hh:mm:ss)</th>
                                <th scope="col">Call To</th>
                                <th scope="col">Call From</th>
                                <th scope="col">Net</th>
                                <th scope="col">Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {checklist.map((row, index) => (
                                !row.hide && (
                                    <tr key={index} style={{ backgroundColor: row.backgroundColor }}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={row.checked}
                                                onChange={() => checkCheckbox(index, !row.checked)}
                                            />
                                        </td>
                                        <td>{row.time}</td>
                                        <td>{row.callTo}</td>
                                        <td>{row.callFrom}</td>
                                        <td>{row.net}</td>
                                        <td>{row.description}</td>
                                    </tr>
                                )
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Checklist;