import React, {useContext} from 'react';

import Table from '../widgets/table';
import DeclareLiftoffButton from '../../images/DeclareLiftoff.PNG';
import ClearLiftoffButton from '../../images/ClearLiftoff.PNG';
import {createGrid, useWindowSize} from '../../helpers/helper';
import LineChartRechart from '../widgets/lineChartRechart';
import useMissionClock from '../../hooks/useMissionClock';
import {
    ftlu,
    esad,
    cdrMonitors,
    cdrCommands,
    events,
    ignition,
    pss,
    framelock,
} from '../../helpers/tableConfigs';
import ClientContext from '../../helpers/ClientContext';

const gridArray = createGrid(100);

const FTM29FTSO = () => {
    const {client, authedUser, widgetState} = useContext(ClientContext);
    const screenSize = useWindowSize();

    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);

    const btnClickHandler = event => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'recordAction',
                identifier: event.currentTarget.dataset.message,
                action: -1,
                positionId: authedUser.position,
            })
        );
    };

    return (
        <>
            <div className="blackBody"/>
            <div
                style={{
                    transformOrigin: 'top left',
                    width: '100%'
                    }}>
                



                <div className="flex-container" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", zoom: '85%'}}>
                    
                    <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 320px)',
                    gap: '10px',
                    marginLeft: '5px',
                    display: 'flex',
                    flexDirection:'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%'
                }}
                >
                    {/*MissionClock*/}
                    <table className="utcTable" style={{
                        color: 'white',
                        border: '1px solid white',
                        fontSize: '1.0rem',
                        fontWeight: 'bold',
                        fontFamily: 'Courier New',
                    }}>
                        <tbody>
                        <tr>
                            <td>Zulu</td>
                            <td style={{textAlign: 'right'}}>{missionClock.utcString}</td>
                        </tr>
                        <tr>
                            <td>LiftOff</td>
                            <td style={{
                                backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                textAlign: 'right'
                            }}>
                                {missionClock.liftoffString}
                            </td>
                        </tr>
                        <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                            <td>T-</td>
                            <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                        </tr>
                        <tr>
                            <td>TALO</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                        </tr>
                        </tbody>
                    </table>
                    {/*Framelock table*/}
                    <div>
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                            config={framelock}
                            radius={12}
                            textSize="1.0rem"
                        />
                    </div>

                </div>

                    
                    <div style={{display:'flex', flexDirection:'row', gap: '10px', alignItems: 'center'}}>

                            <div style={{display:'flex', flexDirection:'column', gap: '5px', height: '20%'}}>
                                {/*FTS Battery Voltage*/}
                                {/*position: 'absolute', left: '143px', top: '175px'*/}
                                <div style={{}}>
                                    <LineChartRechart
                                        shouldShift
                                        chartTitle="FTS Battery Voltage"
                                        baseYDomain={[0, 70]}
                                        baseYTicks={[0, 20, 40, 60]}
                                        yMajorLines={[20, 40]}
                                        axisColor="black"
                                        backgroundColor="rgb(104,131,140)"
                                        chartWidth={721}
                                        chartHeight={175}
                                        lines={[{key: 'batVolt', color: 'blue'}]}
                                        gridColor="rgb(102,102,102)"
                                        gridLineColor="white"
                                        majorGridLineColor="black"
                                        data={widgetState.location.data}
                                        dataPointsShown={30}
                                        referenceMultiple={10}
                                        minorReferenceMultiple={1}
                                        lightBorder="rgb(151, 192, 207)"
                                        darkBorder="rgb(72, 98, 107)"
                                        buttons
                                        margin={{top: 0, right: -5, bottom: 20, left: 10}}
                                        showLatestPoint
                                        xKey="talo"
                                        tickFontSize="1.2em"
                                        xLabel={{text: 'TALO (s)'}}
                                        yLabel={{text: 'V'}}
                                    />
                                </div>
                                {/*FTS Current Draw*/}
                                {/*position: 'absolute', left: '143px', top: '418px'*/}
                                <div style={{}}>
                                    <LineChartRechart
                                        shouldShift
                                        chartTitle="FTS Current Draw"
                                        baseYDomain={[0, 70]}
                                        baseYTicks={[0, 20, 40, 60]}
                                        yMajorLines={[20, 40]}
                                        axisColor="black"
                                        backgroundColor="rgb(104,131,140)"
                                        chartWidth={721}
                                        chartHeight={175}
                                        lines={[{key: 'curDraw', color: 'blue'}]}
                                        gridColor="rgb(102,102,102)"
                                        gridLineColor="white"
                                        majorGridLineColor="black"
                                        data={widgetState.location.data}
                                        dataPointsShown={30}
                                        referenceMultiple={10}
                                        minorReferenceMultiple={1}
                                        lightBorder="rgb(151, 192, 207)"
                                        darkBorder="rgb(72, 98, 107)"
                                        buttons
                                        margin={{top: 0, right: -5, bottom: 20, left: 10}}
                                        showLatestPoint
                                        xKey="talo"
                                        tickFontSize="1.2em"
                                        xLabel={{text: 'TALO (s)'}}
                                        yLabel={{text: 'mA'}}
                                    />
                                </div>
                            </div>

                            {/*CDR Signal Strength*/}
                            {/*position: 'absolute', left: '1100px', top: '175px'*/}
                            <div style={{}}>
                                <LineChartRechart
                                    shouldShift
                                    chartTitle="CDR Signal Strength"
                                    baseYDomain={[0, 60]}
                                    baseYTicks={[0, 15, 30, 45, 60]}
                                    yMajorLines={[15, 30, 45]}
                                    axisColor="black"
                                    backgroundColor="rgb(104,131,140)"
                                    chartWidth={658}
                                    chartHeight={325}
                                    lines={[{key: 'CDRsig', color: 'blue'}]}
                                    gridColor="rgb(102,102,102)"
                                    gridLineColor="white"
                                    majorGridLineColor="black"
                                    data={widgetState.location.data}
                                    dataPointsShown={30}
                                    referenceMultiple={10}
                                    minorReferenceMultiple={1}
                                    lightBorder="rgb(151, 192, 207)"
                                    darkBorder="rgb(72, 98, 107)"
                                    buttons
                                    margin={{left: -10, bottom: 20}}
                                    showLatestPoint
                                    xKey="talo"
                                    tickFontSize="1.2em"
                                    xLabel={{text: 'TALO (s)'}}
                                />
                            </div>

                        </div>
                        <div style={{display:'flex', flexDirection:'column', width: '80%', justifyContent: 'left', gap: '2em'}}>
                            <div style={{display:'flex', flexDirection:'row', gap: '2em'}}>
                                {/*FTLU Monitor*/}
                                {/*position: 'absolute', top: '671px', left: '142px',*/}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="FTLU Monitors"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={ftlu}
                                        radius={7}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>

                                {/*Ignition Enable*/}
                                {/*position: 'absolute', top: '671px', left: '505px',*/}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="Ignition Enable"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={ignition}
                                        radius={11}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>

                                {/*ESAD Monitor*/}
                                {/*position: 'absolute', top: '880px', left: '142px',*/}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="ESAD Monitors"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={esad}
                                        radius={6}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', gap: '2em'}}>
                                {/*PSS Monitors*/}
                                {/*position: 'absolute', top: '880px', left: '505px', border: '1px solid white'*/}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="PSS Monitors"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={pss}
                                        radius={6}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>
                                {/*Events*/}
                                {/*position: 'absolute', top: '856px', left: '780px', */}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="Events"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={events}
                                        radius={11}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>

                                {/*CDR Monitor*/}
                                {/*position: 'absolute', top: '671px', left: '975px', */}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="CDR Monitors"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={cdrMonitors}
                                        radius={7}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>
                                {/*CDR Commands*/}
                                {/*position: 'absolute', top: '875px', left: '975px', */}
                                <div style={{border: '1px solid white'}}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        title="CDR Commands"
                                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                        config={cdrCommands}
                                        radius={11}
                                        textSize="1.0rem"
                                        colSpan="0"
                                    />
                                </div>
                                {/*Declare Liftoff*/}
                                <button
                                    style={{
                                        backgroundColor: '#000000',
                                        position: 'absolute',
                                        left: 1205,
                                        top: 810
                                    }}
                                    onClick={btnClickHandler}
                                    type="button"
                                    data-message="Safety: Declare Liftoff"
                                >
                                    <img style={{width: 170}} src={DeclareLiftoffButton} alt="Declare Liftoff"/>
                                </button>
                                {/*Clear Liftoff*/}
                                <button
                                    style={{
                                        backgroundColor: '#000000',
                                        position: 'absolute',
                                        left: 1392,
                                        top: 810
                                    }}
                                    onClick={btnClickHandler}
                                    type="button"
                                    data-message="Safety: Clear Liftoff"
                                >
                                    <img style={{width: 170}} src={ClearLiftoffButton} alt="Clear Liftoff"/>
                                </button>
                            </div>
                        </div>    

                </div>
            </div>
        </>
    );
};

export default FTM29FTSO;
