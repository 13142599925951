import React, {useContext, useEffect, useState, Fragment} from 'react';
import RadarBar from '../widgets/RadarBar';
import {
    getEndOfArray,
    getFlightPathProps,
    getFlightPathTimeProps,
    useWindowSize,
} from '../../helpers/helper';
import {barDescriptors, barTypes} from '../../enums';
import useListItems from '../../hooks/useListItems';
import ClientContext from '../../helpers/ClientContext';
import LineChartRechart from '../widgets/lineChartRechart';
import RadarColorKey from '../widgets/RadarColorKey';
import LineTicks from '../widgets/lineTicks';

const Radars = () => {
    const screenSize = useWindowSize();
    const {run, configuration, widgetState} = useContext(ClientContext);
    const configurations = useListItems('configurations');
    const [radars, setRadars] = useState([]);
    const [barColors, setBarColors] = useState({});
    const [overallRadarStatuses, setOverallRadarStatuses] = useState([]);
    const [overallRadarEvents, setOverallRadarEvent] = useState([]);

    useEffect(() => {
        if(!configurations.length){
            return;
        }
        const config = configurations.filter(curConfiguration => curConfiguration.id === run.configurationID)[0];
        setRadars(config.radars);
        setBarColors(config.barColors);

        const statuses = [];
        const events = [];
        config.radars.forEach((radar) => {
            radar.statuses.forEach((status) => {
                if(!statuses.includes(status.status)){
                    statuses.push(status.status);
                }
            });
            radar.events.forEach((event) => {
                if(!events.includes(event.event)){
                    events.push(event.event);
                }
            });
        });
        setOverallRadarStatuses(statuses);
        setOverallRadarEvent(events);
    }, [configurations]);

    return (
        <div>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`
            }}>
                <h1 style={{position: 'relative', color: 'white', left: 922}}>Radar</h1>

                {/*Flight Profile Table*/}
                <div style={{position: 'relative', left: 293}}>
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathTimeProps(configuration)}
                        chartTitle="Flight Profile"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={1420}
                        chartHeight={350}
                        lines={[{key: 'offNom', color: 'blue'}, {key: 'alt', color: 'red', dashArray: '5 5'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="white"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="talo"
                        type="number"
                        margin={{left: 45, right: 20, bottom: 25}}
                        tickFontSize="1.1em"
                        xLabel={{text: 'TALO (s)', offset: 5}}
                        yLabel={{text: 'Altitude (ft)', offset: 35}}
                    />
                </div>
                <div style={{paddingTop: '3rem', paddingLeft: '1rem', position: 'absolute', marginLeft: '4rem'}}>
                    {radars.map(radar => {
                        const {startTime, stopTime, statuses, events, timeCreated, title} = radar;

                        return (
                            <Fragment key={timeCreated}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <RadarBar maxTime={getFlightPathProps(configuration).dataPointsShown} time={getEndOfArray(widgetState.location.data, 'time')}
                                              stopTime={stopTime} statuses={statuses} events={events}
                                              type={barTypes.RADAR}
                                              radarWidth={850} statusColors={barColors[barDescriptors.RADAR_STATUSES]}
                                              eventColors={barColors[barDescriptors.RADAR_EVENTS]}/>
                                    <span
                                        style={{color: 'white', fontSize: '1.5rem', marginLeft: '1rem'}}>{title}</span>
                                </div>
                                <LineTicks color="white" startTime={startTime} endTime={getFlightPathProps(configuration).dataPointsShown} width={850}
                                           stepSize={300}/>
                            </Fragment>
                        );
                    })}
                </div>
                <div style={{position: 'absolute', left: '1230px', paddingTop: '40px'}}>
                    <div style={{
                        display: 'table',
                        backgroundColor: 'white',
                        margin: '10px'
                    }}>
                        <RadarColorKey statuses={overallRadarStatuses} events={overallRadarEvents} type={barTypes.RADAR}
                                       barColors={barColors}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Radars;
