import React, {useContext} from 'react';
import {
    getEndOfArray, getFlightPathDownrangeProps,
    getFlightPathProps,
    getFlightPathTimeProps,
    useWindowSize,
} from '../../helpers/helper';
import useMissionClock from '../../hooks/useMissionClock';
import ClientContext from '../../helpers/ClientContext';
import Status from '../widgets/status';
import useListItems from '../../hooks/useListItems';
import DisplayList from '../widgets/displayList';
import LineChartRechart from '../widgets/lineChartRechart';
import BarChartRechart from '../widgets/barChartRechart';
import {csvFiles} from '../../enums';

const Safety2 = () => {
    const screenSize = useWindowSize();
    const {configuration, widgetState} = useContext(ClientContext);
    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime);
    const assets2 = useListItems('assets2');

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
                color: 'white',
            }}
            >
                <div style={{position: 'absolute', top: '80px', display: 'flex'}}>
                    {/*MissionClock*/}
                    <div style={{
                        width: '300px',
                        fontSize: '1.2rem',
                        display: 'table',
                    }}
                    >
                        <table className="utcTable">
                            <tbody>
                            <tr>
                                <td>Zulu</td>
                                <td>{missionClock.utcString}</td>
                            </tr>
                            <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                                <td>TALO</td>
                                <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                            </tr>
                            <tr>
                                <td style={{whiteSpace: 'nowrap'}}>P/L TM Lock</td>
                                <td><Status bounds={{Infinity: 'red'}}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{marginLeft: '77px'}}>
                        <table>
                            <thead>
                            <tr>
                                <td style={{paddingRight: '2rem'}}/>
                                {/*td for eslint*/}
                                <th style={{textAlign: 'center', paddingLeft: '2rem', paddingRight: '2rem'}}>A</th>
                                <th style={{textAlign: 'center', paddingLeft: '2rem', paddingRight: '2rem'}}>B</th>
                            </tr>
                            </thead>
                            {assets2.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.text}</td>
                                    <td style={{textAlign: 'center'}}><Status bounds={{Infinity: item.color}}/></td>
                                    <td style={{textAlign: 'center'}}><Status bounds={{Infinity: item.color2}}/></td>
                                </tr>
                            ))}
                        </table>
                    </div>

                    <div style={{marginLeft: '10rem'}}>
                        <table className="statusTable">
                            <tr>
                                <th>FTS</th>
                                <th>A</th>
                                <th>B</th>
                            </tr>
                            <tr>
                                <td>Battery Volt (V)</td>
                                <td>{getEndOfArray(widgetState.location.data, 'batVolt')}</td>
                                <td>{getEndOfArray(widgetState.location.data, 'battery')}</td>
                            </tr>
                            <tr>
                                <td>Battery Current (mAH)</td>
                                <td>{getEndOfArray(widgetState.location.data, 'curDraw')}</td>
                                <td>{getEndOfArray(widgetState.location.data, 'curDraw')}</td>
                            </tr>
                            <tr>
                                <td>Battery Temperature</td>
                                <td>__</td>
                                <td>__</td>
                            </tr>
                        </table>
                        <div style={{display: 'flex', marginTop: '3rem'}}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>XX</td>
                                    <td>__</td>
                                </tr>
                                <tr>
                                    <td>XY</td>
                                    <td>__</td>
                                </tr>
                                <tr>
                                    <td>YZ</td>
                                    <td>__</td>
                                </tr>
                                </tbody>
                            </table>
                            <table style={{marginLeft: '6rem'}}>
                                <thead>
                                <tr>
                                    <th colSpan="2">Temps:</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>X:</td>
                                    <td>__</td>
                                </tr>
                                <tr>
                                    <td>Y:</td>
                                    <td>__</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div style={{height: '16rem', width: '30rem'}}>
                        <h2>FTS PL AGC</h2>
                        <BarChartRechart/>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    left: '5px',
                    top: '390px'
                }}
                >
                    {/*Test Events div*/}
                    <div style={{
                        backgroundColor: 'black',
                        height: 313,
                        width: 350,
                        overflowY: 'auto',
                        marginLeft: '5px',
                        border: '1px solid white',
                    }}
                    >
                        <p style={{textAlign: 'center', color: 'white'}}><u>Critical Event Sequence</u></p>
                        <DisplayList listName="testEvents"/>
                    </div>
                    <div style={{marginLeft: '2rem'}}>
                        <span>MACH:__</span>
                    </div>
                    {/*Vel vs TALO*/}
                    <div style={{
                        marginLeft: '16rem'
                    }}
                    >
                        <LineChartRechart
                            {...getFlightPathTimeProps(configuration)}
                            chartTitle="Velocity vs TALO"
                            baseYTicks={[0, 3, 6, 9, 12]}
                            yMajorLines={[0, 3, 6, 9, 12]}
                            axisColor="black"
                            backgroundColor="rgb(104,131,140)"
                            chartWidth={900}
                            chartHeight={310}
                            lines={[{key: 'velocity', color: 'blue'}]}
                            gridColor="rgb(102,102,102)"
                            gridLineColor="black"
                            majorGridLineColor="black"
                            data={widgetState.location.data}
                            dataPointsShown={getFlightPathProps(configuration).dataPointsShown}
                            referenceStroke={1}
                            minorReferenceMultiple={0}
                            xKey="talo"
                            type="number"
                            margin={{top: 0, right: 5, bottom: 25, left: 10}}
                            tickFontSize="1.1em"
                            titleColor="white"
                            xLabel={{text: 'Seconds', offset: 5}}
                            yLabel={{text: 'Mach'}}
                        />
                    </div>
                    {/*<div style={{marginLeft: '0.5rem', width: '850px', border: '1px solid white'}}>*/}
                    {/*    <MapLines points={run.chevronPoints}/>*/}
                    {/*</div>*/}
                </div>
                <div style={{
                    position: 'absolute',
                    left: '45.5rem',
                    top: '750px',
                    display: 'flex',
                }}
                >
                    {/*Altitude vs Range*/}
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathDownrangeProps(configuration)}
                        chartTitle="Altitude vs Range"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={900}
                        chartHeight={310}
                        lines={[{key: 'alt', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="black"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        referenceMultiple={configuration.dataSet === csvFiles.BALLISTIC ? 50000 : 100}
                        xKey="rand"
                        type="number"
                        margin={{top: 0, right: 25, bottom: 25, left: 45}}
                        tickFontSize="1.1em"
                        titleColor="white"
                        yLabel={{text: 'ft', offset: 37}}
                    />
                    {/*<div style={{marginLeft: '0.5rem', width: '850px', border: '1px solid white'}}>*/}
                    {/*    <MapLines points={run.keepOutPoints}*/}
                    {/*              circleX={getEndOfArray(widgetState.location.data, 'ftrOneGo')}*/}
                    {/*              circleY={getEndOfArray(widgetState.location.data, 'ofpThru')}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default Safety2;
