import React, {useContext} from 'react';

import {
    createGrid,
    getEndOfArray,
    useWindowSize,
    getFlightPathProps,
    getFlightPathDownrangeProps
} from '../../helpers/helper';
import LineChartRechart from '../widgets/lineChartRechart';
import Horizon from '../widgets/horizon';
import CompassHeading from '../widgets/compassHeading';
import VerticalLineChart from '../widgets/verticalLineChart';
import ColumnGauge from '../widgets/columnGauge';
import ClientContext from '../../helpers/ClientContext';
import useMissionClock from '../../hooks/useMissionClock';

const gridArray = createGrid(100);

const Trajectories = () => {
    const {configuration, widgetState} = useContext(ClientContext);
    const screenSize = useWindowSize();
    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);

    function getToFixed(number){
        if(number){
            return Number(number).toFixed(2);
        }

        return '';
    }

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`
            }}
            >
                {/*Altitude vs Rand(downrange)*/}
                <div style={{position: 'absolute', left: '430px', top: '518px'}}>
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathDownrangeProps(configuration.dataSet)}
                        chartTitle="Altitude vs Downrange"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={950}
                        chartHeight={400}
                        lines={[{key: 'offNom', color: 'blue'}, {key: 'alt', color: 'red', dashArray: '5 5'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="rand"
                        type="number"
                        margin={{left: 50, right: 20, bottom: 25}}
                        tickFontSize="1.2em"
                        yLabel={{text: 'ft', offset: 30}}
                    />
                </div>
                {/*Ranc(crossrange) vs Rand(downrange)*/}
                {/*<div style={{position: 'absolute', left: '1400px', top: '20px'}}>
                    <VerticalLineChart
                        chartTitle="Crossrange vs. Downrange"
                        xDomain={[-500, 0]}
                        baseYTicks={[0, 265, 530, 800]}
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={480}
                        chartHeight={345}
                        lines={[{key: 'ranc', color: 'red'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="black"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        dataPointsShown={639}
                        verticalKey="rand"
                        type="number"
                        bound={[{key: 'ranc', limit: -50, color: 'blue'}, {key: 'ranc', limit: 50, color: 'blue'}]}
                        margin={{left: 35, right: 20, bottom: 25}}
                        tickFontSize="1.2em"
                        xLabel={{text: 'Mi', offset: 5}}
                        yLabel={{text: 'Ft', offset: 10}}
                    />
                </div>*/}
                <div style={{position: 'absolute', left: '950px', top: '160px'}}>
                    <Horizon
                        grid={gridArray}
                        pitch={parseFloat(
                            getEndOfArray(widgetState.location.data, 'pitch')
                        )}
                        roll={parseFloat(
                            getEndOfArray(widgetState.location.data, 'roll')
                        )}
                        width="250px"
                        height="250px"
                        demo={false}
                    />
                    <div style={{color: 'white', fontSize: '1.2rem'}}>
                        Mach: {getToFixed(widgetState.location.data.at(-1)?.velocity)}
                    </div>
                </div>
                <div style={{position: 'absolute', left: '490px', top: '110px'}}>
                    <CompassHeading
                        grid={gridArray}
                        width="375px"
                        height="375px"
                        demo={false}
                        degree={parseFloat(
                            Number(getEndOfArray(widgetState.location.data, 'yaw'))
                        )}
                    />
                </div>
                <div style={{
                    borderRight: '3px solid white',
                    height: '810px',
                    position: 'absolute',
                    left: '400px',
                    top: '145px'
                }}
                >
                    <div style={{
                        position: 'absolute',
                        left: '-340px',
                        top: '0px',
                        fontFamily: 'Courier New',
                        fontWeight: 'bold',
                        color: 'white'
                    }}
                    >
                        <table className="timeTable">
                            <tbody>
                            <tr>
                                <td>Zulu</td>
                                <td>{missionClock.utcString}</td>
                            </tr>
                            <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                                <td>T-Minus</td>
                                <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                            </tr>
                            <tr>
                                <td>LiftOff</td>
                                <td style={{
                                    backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                    textAlign: 'right'
                                }}
                                >
                                    {missionClock.liftoffString}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{
                        position: 'absolute',
                        left: '-310px',
                        top: '160px'
                    }}
                    >
                        {/*Acceleration Column Gauge*/}
                        <ColumnGauge
                            title="Acceleration"
                            leftColumnTitle="Lon"
                            rightColumnTitle="Lat"
                            columnWidth="250"
                            columnHeight="500"
                            lineScale={1}
                            start={250}
                            lon={widgetState.location.data[0].lon2}
                            lat={widgetState.location.data[0].lat2}
                        />
                    </div>
                </div>
                <hr
                    style={{
                        backgroundColor: 'white',
                        width: '355px',
                        height: '2px',
                        position: 'absolute',
                        left: '45px',
                        top: '260px'
                    }}
                />
            </div>
        </>
    );
};

export default Trajectories;
