import React, {useReducer, useEffect, useContext} from 'react';

import {csvFiles} from '../../enums';
import Table from '../widgets/table';
import ColumnGauge from '../widgets/columnGauge';
import MotionEnableButton from '../../images/MotionEnable.png';
import {
    createGrid,
    getFlightPathDownrangeProps,
    getFlightPathProps, getFlightPathTimeProps,
    useWindowSize
} from '../../helpers/helper';
import LineChartRechart from '../widgets/lineChartRechart';
import {
    framelock,
    trackSourceTiming,
    altitude,
    flightNavStatus,
    gpsSourceStatus
} from '../../helpers/tableConfigs';
import ClientContext from '../../helpers/ClientContext';
import useMissionClock from '../../hooks/useMissionClock';

const gridArray = createGrid(100);

const FTM29 = () => {
    const {client, configuration, authedUser, widgetState} = useContext(ClientContext);
    const screenSize = useWindowSize();

    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);

    const btnClickHandler = event => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'recordAction',
                identifier: event.currentTarget.dataset.message,
                action: -1,
                positionId: authedUser.position,
            })
        );
    };

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    marginLeft: '5px',
                }}
                >
                    {/*MissionClock*/}
                    <table className="utcTable" style={{
                        color: 'white',
                        border: '1px solid white',
                        fontSize: '1.3rem',
                        width: '320px',
                        fontWeight: 'bold',
                        fontFamily: 'Courier New',
                    }}>
                        <tbody>
                        <tr>
                            <td>Zulu</td>
                            <td style={{textAlign: 'right'}}>{missionClock.utcString}</td>
                        </tr>
                        <tr>
                            <td>LiftOff</td>
                            <td style={{
                                backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                textAlign: 'right'
                            }}>
                                {missionClock.liftoffString}
                            </td>
                        </tr>
                        <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                            <td>T-</td>
                            <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                        </tr>
                        <tr>
                            <td>TALO</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                        </tr>
                        </tbody>
                    </table>

                    {/*Framelock table*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        top={40}
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={framelock}
                        left={310}
                        radius={12}
                        textSize="1.3rem"
                        tableWidth={300}
                    />
                </div>

                {/*Altitude vs Range*/}
                <div style={{position: 'absolute', left: '20px', top: '200px'}}>
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathDownrangeProps(configuration.dataSet)}
                        chartTitle="Altitude vs Range"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={620}
                        chartHeight={290}
                        lines={[{key: 'alt', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        referenceMultiple={configuration.dataSet === csvFiles.BALLISTIC ? 50000 : 100}
                        xKey="rand"
                        type="number"
                        margin={{top: 0, right: 25, bottom: 25, left: 45}}
                        tickFontSize="1.2em"
                        titleColor="white"
                        yLabel={{text: 'ft', offset: 37}}
                    />
                </div>
                {/*Vel vs Range*/}
                <div style={{position: 'absolute', left: '20px', top: '535px'}}>
                    <LineChartRechart
                        {...getFlightPathDownrangeProps(configuration.dataSet)}
                        chartTitle="Velocity vs Range"
                        baseYDomain={[0, 12]}
                        baseYTicks={[0, 3, 6, 9, 12]}
                        yMajorLines={[0, 3, 6, 9, 12]}
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={620}
                        chartHeight={290}
                        lines={[{key: 'velocity', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        dataPointsShown={getFlightPathProps(configuration).dataPointsShown}
                        referenceMultiple={configuration.dataSet === csvFiles.BALLISTIC ? 50000 : 100}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="rand"
                        type="number"
                        margin={{top: 0, right: 25, bottom: 25, left: 45}}
                        tickFontSize="1.2em"
                        titleColor="white"
                        yLabel={{text: 'Mach'}}
                    />
                </div>
                {/*Altitude vs TALO*/}
                <div style={{position: 'absolute', left: '1300px', top: '75px'}}>
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathTimeProps(configuration)}
                        chartTitle="Altitude vs TALO"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={620}
                        chartHeight={290}
                        lines={[{key: 'offNom', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="talo"
                        type="number"
                        margin={{top: 0, right: 25, bottom: 25, left: 45}}
                        tickFontSize="1.2em"
                        titleColor="white"
                        xLabel={{text: 'Seconds', offset: 5}}
                        yLabel={{text: 'ft', offset: 37}}
                    />
                </div>
                {/*Vel vs TALO*/}
                <div style={{position: 'absolute', left: '1300px', top: '410px'}}>
                    <LineChartRechart
                        {...getFlightPathTimeProps(configuration)}
                        chartTitle="Velocity vs TALO"
                        baseYTicks={[0, 3, 6, 9, 12]}
                        yMajorLines={[0, 3, 6, 9, 12]}
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={620}
                        chartHeight={290}
                        lines={[{key: 'velocity', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        dataPointsShown={getFlightPathProps(configuration).dataPointsShown}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="talo"
                        type="number"
                        margin={{top: 0, right: 25, bottom: 25, left: 45}}
                        tickFontSize="1.2em"
                        titleColor="white"
                        xLabel={{text: 'Seconds', offset: 5}}
                        yLabel={{text: 'Mach'}}
                    />
                </div>
                {/*Track Source Timing*/}
                <div style={{
                    position: 'absolute',
                    left: '1300px',
                    top: '870px',
                    border: '1px solid white',
                }}>
                    <Table
                        draggable={false}
                        grid={gridArray}
                        title="Track Source Timing"
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={trackSourceTiming}
                        radius={15}
                        textSize="1.3rem"
                        tableWidth={300}
                    />
                </div>

                {/*Altitude*/}
                <div style={{
                    position: 'absolute',
                    left: '850px',
                    top: '80px',
                    border: '1px solid white',
                    width: '300px',
                }}>
                    <Table
                        draggable={false}
                        grid={gridArray}
                        title="Altitude"
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={altitude}
                        radius={15}
                        textSize="1.3rem"
                    />
                </div>
                {/*Flight Nav Status*/}
                <div style={{
                    position: 'absolute',
                    left: '850px',
                    top: '220px',
                    border: '1px solid white',
                    width: '300px',
                }}>
                    <Table
                        draggable={false}
                        grid={gridArray}
                        title="Flight Nav Status"
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={flightNavStatus}
                        radius={15}
                        textSize="1.3rem"
                    />
                </div>
                {/*GPS Source Status*/}
                <div style={{position: 'absolute', left: '20px', top: '870px', border: '1px solid white'}}>
                    <Table
                        draggable={false}
                        grid={gridArray}
                        title="GPS Source Status"
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={gpsSourceStatus}
                        radius={15}
                        textSize="20px"
                        tableWidth={330}
                        rowPadding="2px"
                    />
                </div>
                {/*Acceleration Column Gauge*/}
                <div style={{position: 'absolute', top: '410px', left: '700px'}}>
                    <ColumnGauge
                        title="Acceleration"
                        leftColumnTitle="Lon"
                        rightColumnTitle="Lat"
                        lineScale={1}
                        start={200}
                        lon={widgetState.location.data[0].lon2}
                        lat={widgetState.location.data[0].lat2}
                        columnHeight="400px"
                        columnWidth="200px"
                    />
                </div>
                {/*MotorPressure Column Gauge*/}
                <div style={{position: 'absolute', top: '410px', left: '1059px'}}>
                    <ColumnGauge
                        title="Motor Pressure"
                        leftColumnTitle="s1"
                        rightColumnTitle="s2"
                        lineScale={.5}
                        start={200}
                        lon={widgetState.location.data[0].lon2}
                        lat={widgetState.location.data[0].lat2}
                        columnHeight="400px"
                        columnWidth="200px"
                    />
                </div>
                {/*Motion Enable Button*/}
                <button style={{backgroundColor: '#000000', position: 'absolute', left: '360px', top: '920px'}}
                        onClick={btnClickHandler}
                        type="button"
                        data-message="Flight H&S: Motion Enable"
                >
                    <img style={{width: 190}} src={MotionEnableButton} alt="Motion Enable"/>
                </button>
                {/*(One) Footer of the page*/}
                <div style={{position: 'absolute', top: '970px', left: '600px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>Site: 201610</b>
                    </h1>
                </div>
                <div style={{position: 'absolute', top: '970px', left: '758px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>Src: 0</b>
                    </h1>
                </div>
                <div style={{position: 'absolute', top: '970px', left: '850px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>Rng:UNKNOWN</b>
                    </h1>
                </div>
                <div style={{position: 'absolute', top: '970px', left: '1000px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>TALO:-5012.11</b>
                    </h1>
                </div>
                <div style={{position: 'absolute', top: '970px', left: '1160px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>Vel:UNKNOWN</b>
                    </h1>
                </div>
                <div style={{position: 'absolute', top: '1040px', left: '1000px'}}>
                    <h1 style={{fontSize: '18px', color: 'white', fontFamily: 'Courier New'}}>
                        <b>Alt:UNKNOWN</b>
                    </h1>
                </div>
            </div>
        </>
    );
};

export default FTM29;
